import './App.css';

import { useState, useEffect } from "react";

import { ethers } from "ethers";
import { WHITELIST } from "./whitelist";

function App() {
  const [addressInput, setAddressInput] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const WARNING_TEXT = "Not a valid address";
  const IS_WHITELISTED = "Whitelisted! Stay tuned for presale details";
  const NOT_WHITELISTED = "Not whitelisted... But it's not too late!  There are a few whitelist spots left for the most active members on Twitter and Discord.";

  useEffect(() => {
    let addressString;
    try {
      addressString = ethers.getAddress(addressInput.toLowerCase())
    } catch (e) {
      addressString = addressInput;
    }

    if (ethers.isAddress(addressString)) {
      setShowWarning(false);
      if (WHITELIST.has(addressString)) {
        setIsWhitelisted(true);
      } else {
        setIsWhitelisted(false);
      }
    } else if (addressInput === "") {
      setShowWarning(false);
      setIsWhitelisted(false);
    } else {
      setShowWarning(true);
      setIsWhitelisted(false);
    }
  }, [addressInput])

  return (
    <div className="App">
      <div className="title-test">
        Gloop Presale - Whitelist Checker
      </div>

      <input
        className="address-input"
        placeholder="Paste your address to check"
        value={addressInput}
        onChange={e => setAddressInput(e?.target?.value || "")}
      />

      <div className="instructions">
        {addressInput === "" ? <span style={{ fontSize: '16px', textAlign: 'center' }}>Paste your Address</span> : (
          isWhitelisted ?
            <span style={{ fontSize: '16px', textAlign: 'center', color: "#19FB80" }}>{IS_WHITELISTED}</span> : (
              showWarning ?
                <span style={{ fontSize: '16px', textAlign: 'center' }}>{WARNING_TEXT} </span> :
                <span style={{ fontSize: '16px', textAlign: 'center' }}> {NOT_WHITELISTED}</span>))}
      </div>
    </div>
  );
}

export default App;
